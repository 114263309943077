import { render, staticRenderFns } from "./index.vue?vue&type=template&id=42d44bd3&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHeader: require('/home/runner/work/webpage/webpage/components/home/header/header.vue').default,HomePrograms: require('/home/runner/work/webpage/webpage/components/home/programs/programs.vue').default,HomeHowItWorks: require('/home/runner/work/webpage/webpage/components/home/how-it-works/how-it-works.vue').default,BaseQuizB: require('/home/runner/work/webpage/webpage/components/base/quiz-b/quiz-b.vue').default,HomeFeaturedIn: require('/home/runner/work/webpage/webpage/components/home/featured-in/featured-in.vue').default,BaseTestimonialsImage: require('/home/runner/work/webpage/webpage/components/base/testimonials-image/testimonials-image.vue').default,BaseFloatingButton: require('/home/runner/work/webpage/webpage/components/base/floating-button/floating-button.vue').default})
