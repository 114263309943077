//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from '~/mixins/page'

export default {
  mixins: [
    PageMixin
  ],
  data () {
    return {
      showFloatingButton: false
    }
  },
  head () {
    return {
      ...this.createSeoTags({
        title: this.$t('home.seo.title'),
        description: this.$t('home.seo.description'),
        url: 'https://get-base.com/',
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.getStaticsObject('/home/header.webp')
          }
        ]
      })
    }
  },
  mounted () {
    window.location.href = 'https://www.superpower.com/s/base';

    window.addEventListener('scroll', () => {
      if (this.$refs.header && this.$refs.header.$el) {
        this.showFloatingButton = !this.isElementInViewport(this.$refs?.header?.$el)
      }
    }, { passive: true })
  },
  methods: {
    trackQuizClicked () {
      this.addDDAction('Quiz clicked', { path: this.$route.path })
    }
  }
}
